// Header.js
import React from 'react';
import './styles/header.css';
import RoundedCard from './hooks/roundedcard';


function Header() {
  return (
    <header className="myheader">
    <div className="logo">
    <a href="index.html">
      <img src="assets/media/LogoE2.png" width={20} />
  </a>
    </div>
    <div className="icon">
      <a>
      <RoundedCard/>
    </a>
    </div>
  </header>
  );
}

export default Header;
