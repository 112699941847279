import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import SignIn from './components/pages/signin';
import Detail from './components/pages/eventdetail';
import Dashboard from './components/pages/dashboard';
import Newevent from './components/pages/newevent';
import Evendetail from './components/pages/eventdetail';
import Header from './components/pages/header';
import DetailHeader from './components/pages/eventdetailheader';
import Notfound from './components/pages/404';

export default function BasicExample() {
  return (
    <Router>
      <div>
        <Switch>
        <Route exact path="/" component={SignIn} />
          <Route exact path="/eventdetail" component={Detail} />
          <Route exact path="/newevent" component={Newevent} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/eventdetail" component={Evendetail} />
          <Route exact path="/header" component={Header} />
          <Route exact path="/detailHeader" component={DetailHeader} />
          <Route exact path="/notfound" component={Notfound} />
          {/* Add a catch-all route for any other URL */}
          <Route component={Notfound} />

        </Switch>
      </div>
    </Router>
  );
}




