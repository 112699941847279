import React, {useState} from 'react';
import './styles/newevent.css';
import Eventheader from './eventheader'; // Import your Header component
import http from './hooks/http'; // Import path for the axios function

function Newevent() {

      const [invalidInput, setInvalidInput] = useState(false); // Initially, hide the invalid input prompt 
      const [titleempty, setTitleempty] = useState(false); // Initially, hide the empty title input prompt
      const [descempty, setDescempty] =  useState(false); // Initially, hide the empty desc input prompt
      const [dateempty, setDateempty] = useState(false); // Initially, hide the empty date input prompt
      const [timeempty, setTimeempty] = useState(false); // Initially, hide the empty time input prompt
      const [capacityempty, setCapacityempty] = useState(false); // Initially, hide the empty capacity input prompt
      const [eventsuccess, setEventsuccess] = useState(false); // Initially, hide the empty capacity input prompt
      
      const token = localStorage.getItem('HutsyConnect_token')
      const axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      };
      // Define a state variable to store various input values
      const [state , setState] = useState({
        eventTitle: '',                // State for event title
        eventDesc: '',             // State for event description
        eventCapacity: '',         // State for event capacity
        eventTime: '',          // State for event time
        eventdate: '',             // State for event date
      });

      const handleClose = () => {
          console.log('Close icon clicked');
          window.location.href='dashboard'
        };

      // Function to handle input field changes
      const handleChange = (e) => {
        // Extract the 'id' and 'value' from the input element
        const { id, value } = e.target;

        // Update the 'state' using the previous state and the new value
        setState(prevState => ({
          ...prevState,   // Preserve the previous state
          [id]: value     // Update the specific property ('id') with the new 'value'
        }));
      }

      const CreateEvent = async (e) => {
        // Prevent the default form submission behavior
        e.preventDefault();
        let hasError = false;
            if (state.eventTitle.trim() === '') {
              setTitleempty(true);
            }else if (state.eventDesc.trim() === '') {
              setDescempty(true);
            } else if (state.eventdate.trim() === '') {
              setDateempty(true);
            } else if (state.eventCapacity.trim() === '') {
              setCapacityempty(true);
            } else if (state.eventTime.trim() === '') {
              setTimeempty(true);
            }else{
              try {
                // Create a userData object with user event data
                const userData = {
                  title: state.eventTitle, // Extracted from state
                  description: state.eventDesc, // Extracted from state
                  startsAt: state.eventdate, // Extracted from state
                  capacity: state.eventCapacity, // Extracted from state
                  attendees: 10, // Extracted from state
                  owner: 10
                };
                // Send a POST request to the '/events' endpoint with event data
                const response = await http.post('/events', userData, axiosConfig);
                // Log the response data upon successful login
                console.log(response.data);
                setEventsuccess(true)
                // show successful mesage
            
              } catch (error) {
                if (error.response && error.response.data) {
                  // Handle specific errors here, e.g., display an error message to the user
                  // setinvalidCombination(true);
                } else {
                  // Log any other unexpected errors
                  console.error(error);
                }
              }
           }
        };

  return (
    <div>
       <div>
       <Eventheader />
       </div>
    <div className="App">
   
        
      <div className="form-container">
        <p className='eventheader'>Create new Event</p>
        <p className='text-muted'>Enter Details Below</p>
        <form>
          <div className={`fv-row mb-8 ${titleempty ? 'blue-border' : ''}`}>

                    <input 
                    type="text" 
                    placeholder="Title"  
                    name="eventTitle" 
                    id='eventTitle' 
                    value={state.eventTitle} 
                    onChange={handleChange} 
                    autoComplete="off" 
                    className="form-control bg-transparent" />
                  </div>
                   <small className={titleempty ? 'error-msg' : 'hideerror-msg'}>Title is Required</small>


                   <div className={`fv-row mb-8 ${descempty ? 'blue-border' : ''}`}>
                    <input 
                    type="text" 
                    placeholder="Description"  
                    name="eventDesc" 
                    id='eventDesc' 
                    value={state.eventDesc} 
                    onChange={handleChange} 
                    autoComplete="off" 
                    className="form-control bg-transparent" />
                  </div>
                  <small className={descempty ? 'error-msg' : 'hideerror-msg'}>Description is Required</small>

                  <div className={`fv-row mb-8 ${dateempty ? 'blue-border' : ''}`}>
                    <input 
                    type="date" 
                    placeholder="Date"  
                    name="eventdate" 
                    id='eventdate' 
                    value={state.eventdate} 
                    onChange={handleChange} 
                    autoComplete="off" 
                    className="form-control bg-transparent" />
                  </div>
                  <small className={dateempty ? 'error-msg' : 'hideerror-msg'}>Date is Required</small>

                  <div className={`fv-row mb-8 ${timeempty ? 'blue-border' : ''}`}>
                    <input 
                    type="time" 
                    placeholder="time"  
                    name="eventTime" 
                    id='eventTime' 
                    value={state.eventTime} 
                    onChange={handleChange} 
                    autoComplete="off" 
                    className="form-control bg-transparent" />
                  </div>
                  <small className={timeempty ? 'error-msg' : 'hideerror-msg'}>Time is Required</small>


                 
                  <div className={`fv-row mb-8 ${capacityempty ? 'blue-border' : ''}`}>
                    <input 
                    type="text" 
                    placeholder="eventCapacity"  
                    name="eventCapacity" 
                    id='eventCapacity' 
                    value={state.eventCapacity} 
                    onChange={handleChange} 
                    autoComplete="off" 
                    className="form-control bg-transparent" />
                  </div>
                  <small className={capacityempty ? 'error-msg' : 'hideerror-msg'}>Capacity is Required</small>
                  <br/>
                  <small style={{color: '#2D0DEF'}} className={eventsuccess ? 'error-mesg' : 'hideerror-msg'}>EVENT CREATED SUCCESFULLY <a href='dashboard'>Back to Dashbaord?</a></small>
                  <br/>
          
          <button onClick={CreateEvent} type="submit">Create new Event</button>
        </form>
      </div>
    </div>
    </div>
  );
}

export default Newevent;
