import React, {useState, useEffect} from 'react';
import http from './hooks/http'; // Import path for the axios function
import DetailHeader from './eventdetailheader'; // Import your Header component
import Footer from './footer'; // Import your Footer component

  function Detail(){

    const urlParams = new URLSearchParams(window.location.search);
    const eventid = urlParams.get('eventid');
    
      // Function to fetch event detail
      const fetchEvents = async () => {
        try {
          // Make a GET request to the API endpoint that provides events
          // const response = await axios.get('/events'); // Replace with your actual API endpoint
          const response = await http.get('/events/'+eventid);
          // Check if the response data contains events
          if (response.data && Array.isArray(response.data)) {
            console.log(response.data)
            // setMyevents(events);
            // console.log(myevents)
          }
        } catch (error) {
          // Handle errors, e.g., display an error message or log the error
          console.error('Error fetching events:', error);
        }
      };

        // Call the fetchEvents function when your component mounts
        useEffect(() => {
          fetchEvents();
        }, []);

return ( 
<div>

  <div className="app-page  flex-column flex-column-fluid">     
  <DetailHeader/>
        <div className="app-wrapper  flex-column flex-row-fluid " id="kt_app_wrapper">
          <div className="app-main flex-column flex-row-fluid " id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
              <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">
                  <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                  <div className="link-container">
                      <a href="#" className='text-muted'>DETAIL EVENT: qw0qw9398239ouwewkjw</a>
                    </div>
                  </div>
                </div>
              </div>
              <div id="kt_app_content" className="app-content  flex-column-fluid ">
                <div id="kt_app_content_container" className="app-container  container-xxl ">
                  <div >
                  <div className="row g-5 g-xl-12 mb-5 mb-xl-12"> 
                    <div className="col-xl-8">
                      <div className="card card-flush overflow-hidden h-lg-100" >
                        <div className="card-header pt-5">
                          <p className="text-muted">April 4 2017 - 2:17 PM</p>           
                        </div>
                        <div className="card-body">
                          <h3>How to Get Angry</h3>
                          <p className="text-muted">Tom Watts</p>
                          <br />
                          <p className="text-muted">I will show you how to get angry in a second</p>
                        </div>
                        <div className="card-footer">
                          <div className="row">
                            <div className="col-lg-6 text-muted">
                              <i className="fa fa-user" /> 9 of 31
                            </div>
                            <div className="col-lg-6 text-muted">
                              <button className="btn btn-secondary btn-sm card-button">EDIT</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="card card-flush overflow-hidden h-lg-100">
                        <div className="card-body">
                          <h3>Attendees</h3>
                          <br />
                          <p className="text-muted">I will show you how to get angry in a second</p>
                        </div>
                        <div className="card-footer">
                          <div className="row">
                            <div className="col-lg-6 text-muted">
                            </div>
                            <div className="col-lg-6 text-muted">
                              <button className="btn btn-secondary btn-sm card-button">EDIT</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div> 
                </div>
              </div>
              <Footer/>
            </div>
          </div>
        </div>
      </div>
      </div>
      );

  };

export default Detail;
