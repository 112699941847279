import React, {useState} from 'react';
import http from './hooks/http'; // Import path for the axios function
import './styles/signin.css';
import Spinner from './hooks/spinner'; 

  function SignIn(){

          // Define state variables for showing/hiding sign-in and sign-up forms
      const [showSignIn, setShowSignIn] = useState(true); // Initially, show the sign-in form
      const [showSignUp, setShowSignUp] = useState(false); // Initially, hide the sign-up form
      const [showPassword, setShowPassword] = useState(false); // Initially, hide the password input
      const [invalidInput, setInvalidInput] = useState(false); // Initially, hide the invalid input prompt 
      const [invalidCombination, setinvalidCombination] = useState(false); // Initially, hide the invalid combination
      const [isLoading, setIsLoading] = useState(false); //to hide or show the spinner
      

      

      // Define a state variable to store various input values
      const [state , setState] = useState({
        loginEmail: '',                // State for login email input
        loginPassword: '',             // State for login password input
        RegisterFirstname: '',         // State for registration first name input
        Registerlastname: '',          // State for registration last name input
        RegisterEmail: '',             // State for registration email input
        RegisterPassword: '',          // State for registration password input
        phone: '',                     // State for phone input (if needed)
        RegisterPasswordRepeat: ''     // State for repeating registration password input
      });

        // Define Axios configuration for HTTP requests
      const axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8', // Set the content type to JSON
          'Accept': 'application/json'
        }
      };

      // Function to handle input field changes
      const handleChange = (e) => {
        // Extract the 'id' and 'value' from the input element
        const { id, value } = e.target;

        // Update the 'state' using the previous state and the new value
        setState(prevState => ({
          ...prevState,   // Preserve the previous state
          [id]: value     // Update the specific property ('id') with the new 'value'
        }));
      }


        // Function to handle navigating to the sign-up form
      const gotoSignup = (e) => {
        e.preventDefault();  // Prevent the default behavior of form submission
        setShowSignIn(false); // Hide the sign-in form
        setShowSignUp(true);  // Show the sign-up form
      };

      // Function to handle navigating to the sign-in form
      const gotoSignin = (e) => {
        e.preventDefault();  // Prevent the default behavior of form submission
        setShowSignIn(true);  // Show the sign-in form
        setShowSignUp(false); // Hide the sign-up form
      };

      const togglePasswordVisibility = (e) => {
        e.preventDefault();  // Prevent the default behavior of form submission
        setShowPassword(!showPassword);
      };

      
      // Function to handle user registration
      const Login = async (e) => {
        // Prevent the default form submission behavior
        e.preventDefault();
      
        // Check if email and password are provided
        if (!state.loginEmail || !state.loginPassword) {
          setInvalidInput(true);
          return; // Exit early if data is missing
        }
      
        try {
          // Create a userData object with user login data
          const userData = {
            email: state.loginEmail, // Extracted from state
            password: state.loginPassword, // Extracted from state
          };
      
          // Send a POST request to the '/auth/native' endpoint with user data
          const response = await http.post('/auth/native', userData, axiosConfig);
      
          // Log the response data upon successful login
          console.log(response.data);
      
          // Store data in local storage for later use
          localStorage.setItem("HutsyConnect_firstname", response.data.user.firstName);
          localStorage.setItem("HutsyConnect_lastname", response.data.user.lastName);
          localStorage.setItem("HutsyConnect_email", response.data.user.email);
          localStorage.setItem("HutsyConnect_token", response.data.jwt);
      
          // Redirect to the dashboard page
          window.location.href = 'dashboard';
      
        } catch (error) {
          if (error.response && error.response.data) {
            // Handle specific errors here, e.g., display an error message to the user
            setinvalidCombination(true);
          } else {
            // Log any other unexpected errors
            console.error(error);
          }
        }
      };
      
      
      

return ( 
<div>

    <div className="">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="sidelayout-wrap">
            <div>
              <img src="assets/media/LogoE.png" width={20} className="sidelayout-logo" />
            </div>
            <div >
              <h2 className="lowerText">
                GIVE YOURSELF <br />
                SOME CREDIT
              </h2>
            </div>
          </div>

          <div className="d-flex flex-lg-row-fluid w-lg-70 bgi-size-cover bgi-position-center signin-mobilepage">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
           
              <div className="p-10" style={{width: '500px'}}>
                <form  noValidate="novalidate" action="#" style={{ display: showSignIn ? 'block' : 'none' }}>
                <div className="">
                    <p className="signupText">
                        Don't have an account? <a href="" onClick={gotoSignup}><b>SIGN UP</b></a>
                    </p>
                </div>
                <div className='mobileviewLogo'>
                  <img className='mobilelogoImage' src='assets/media/LogoE2.png'/>
                </div>
                  <div className="mb-11">
                    <p className="text-dark text-singin fw-bolder mb-6 signinheader">
                      Sign in to HutsyConnect
                    </p>
                    
                   <br/>
                    
                    <div className="text-gray-500 fw-semibold fs-6 signinsubheader">
                      Enter your details below
                    </div>
                  </div>

                  
                  <div className={`fv-row mb-8 ${invalidInput ? 'blue-border' : ''}`}>
                    <input 
                    type="text" 
                    placeholder="Email"  
                    name="loginEmail" 
                    id='loginEmail' 
                    value={state.loginEmail} 
                    onChange={handleChange} 
                    autoComplete="off" 
                    className="form-control bg-transparent" />
                  </div>
                  <div className={`input-group input-group-solid mb-5 ${invalidInput ? 'blue-border' : ''}`}>
                    <input                    
                     type={showPassword ? 'text' : 'password'} // Toggle input type based on `showPassword`
                    placeholder="Password" 
                    name="loginPassword" 
                    id='loginPassword'
                    value={state.loginPassword} 
                    onChange={handleChange} 
                    autoComplete="off" 
                    className="form-control" />
                    <span className="input-group-text">
                    <a href="" onClick={togglePasswordVisibility}><i className="fa fa-eye fa-3x" /></a>
                    </span>
                  </div>
                  <span className="error-message" style={{ display: invalidCombination ? 'block' : 'none' }}>
                      Oops! That email and password combination is not valid.
                   <br/>
                   <br/>
                   <br/>
                   <br/>
                   </span>
                  <span className="signup-mobile">
                    Dont have an account? <a><b>SIGN UP</b></a>
                  </span>
                  <span className="error-message" style={{ display: invalidInput ? 'block' : 'none' }}>
                    Email and password is required
                  </span>
                  <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                    <div />
                  </div>
                  <div className="mb-10">
                    <button onClick={Login} type="submit" className="siginButton btn btn-lg btn-primary">
                      SIGN IN
                    </button>
                  </div>
                  {/* {isLoading ? <Spinner /> : <div>Your content here</div>} */}
                </form>


                <form className="showSignUp" noValidate="novalidate" action="#" style={{ display: showSignUp ? 'block' : 'none' }}>
                <div className="link-primary">
                    <p className="signupText">
                       Already have an account? <a href="" onClick={gotoSignin}><b>SIGN IN</b></a>
                    </p>
                </div>
                  <div className="mb-11">
                    <h1 className="text-dark fw-bolder mb-6">
                      Get Started Absolutely free.
                    </h1>
                    <div className="text-gray-500 fw-semibold fs-6">
                      Enter your details below
                    </div>
                  </div>
                  <div className="fv-row mb-12">
                    <input 
                    type="text" 
                    placeholder="First Name" 
                    name="RegisterFirstname" 
                    id='RegisterFirstname' 
                    value={state.RegisterFirstname} 
                    onChange={handleChange} 
                    autoComplete="off" 
                    className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row mb-8">
                    <input 
                    type="text" 
                    placeholder="Last Name" 
                    name="Registerlastname" 
                    id='Registerlastname' 
                    value={state.Registerlastname} 
                    onChange={handleChange} 
                    autoComplete="off" 
                    className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row mb-8">
                    <input 
                    type="text" 
                    placeholder="Email" 
                    name="RegisterEmail" 
                    id='RegisterEmail' 
                    value={state.RegisterEmail} 
                    onChange={handleChange} 
                    autoComplete="off" 
                    className="form-control bg-transparent" />
                  </div>
                  <div className="input-group input-group-solid mb-5">
                   <input 
                    type={showPassword ? 'text' : 'password'} // Toggle input type based on `showPassword`
                     placeholder="Password" 
                     name="RegisterPassword" 
                     id='RegisterPassword' 
                     value={state.RegisterPassword} 
                     onChange={handleChange} 
                     autoComplete="off" 
                     className="form-control" /> :
                    <span className="input-group-text" id="basic-addon2">
                      <a href="" onClick={togglePasswordVisibility}><i className="fa fa-eye fa-3x" /></a></span>
                  </div>
                  <div className="input-group input-group-solid mb-5">
                    <input 
                    type={showPassword ? 'text' : 'password'} // Toggle input type based on `showPassword`
                    placeholder="Repeat Password" 
                    name="RegisterPasswordRepeat" 
                    id='RegisterPasswordRepeat'
                     onChange={handleChange} 
                     value={state.RegisterPasswordRepeat} 
                     autoComplete="off" 
                     className="form-control" />
                    <span className="input-group-text" id="basic-addon2">
                    <a href="" onClick={togglePasswordVisibility}><i className="fa fa-eye fa-3x" /></a>
                      </span>
                  </div>
                  
                  <div className="mb-10">
                    <button  className="siginButton btn btn-lg btn-primary">
                      SIGN UP
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      );

  };

export default SignIn;
