// Header.js
import React from 'react';
import './styles/eventheader.css';
import RoundedCard from './hooks/roundedcard';


function DetailHeader() {
  return (
    <header className="myheader">
    <div className="logo">
      <a href="index.html">
        <img src="assets/media/LogoE2.png" width={20} />
      </a>
    </div>
    <div className="centered-text">
      <a href="/dashboard"><i className='fas fa-arrow-left'></i> Back to Events</a>
    </div>
    <div className="icon">
      <a>
        <RoundedCard />
      </a>
    </div>
  </header>
  

  );
}

export default DetailHeader;
