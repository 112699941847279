import React, { useEffect, useState } from 'react';
import './roundedcard.css';

function RoundedCard() {
  const [initials, setInitials] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleSelectChange = (e) => {
    const selectedOption = e.target.value;
    console.log(selectedOption)
    if (selectedOption === "logout") {
      // Clear the HutsyConnect token from localStorage
    localStorage.removeItem('HutsyConnect_token');
    localStorage.removeItem('HutsyConnect_lastname');
    localStorage.removeItem('HutsyConnect_firstname');
    // Redirect the user to the root page (replace with your desired URL)
    window.location.href = '/';
    }
  };

  

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    // Retrieve first name and last name from local storage
    const storedFirstName = localStorage.getItem('HutsyConnect_firstname');
    const storedLastName = localStorage.getItem('HutsyConnect_lastname');

    if (storedFirstName && storedLastName) {
      // Extract the first letters and convert them to uppercase
      const firstInitial = storedFirstName[0].toUpperCase();
      const lastInitial = storedLastName[0].toUpperCase();

      // Combine the initials
      setInitials(firstInitial + lastInitial);
      setFirstName(storedFirstName);
      setLastName(storedLastName);
    }
  }, []);

  return (
    <>
     
     <div style={{ display: 'flex', alignItems: 'center' }}>
  <div className="rounded-card">
    <div className="initials">{initials}</div>
  </div>
  <span>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <label htmlFor="showDropdown">{firstName} {lastName}</label>
      <select id="showDropdown" style={{ width: '10px' }} onChange={handleSelectChange}>
        <option value=''></option>
        <option value="logout">Logout</option>
      </select>
    </div>
  </span>
</div>

    </>
  );
}

export default RoundedCard;
