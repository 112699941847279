import React, {useState, useEffect} from 'react';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import http from './hooks/http'; // Import path for the axios function
import './styles/dashboard.css';

  function Dashboard(){

    const [selectedView, setSelectedView] = useState('grid');
    const [myevents, setMyevents] = useState(true); // state management for events
    const [allevents, setallevents] = useState(false); // state management for events
    const [mypastevents, setMypastevents] = useState(false); // state management for events
    const [myfutureevents, setMyfutureevents] = useState(false); // state management for events
   

    function formatApiDate(apiDate) {
      const formattedDate = new Date(apiDate).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    
      return formattedDate;
    }

    const handleEventDetail = (e) => {
      e.preventDefault();
      if(!e.currentTarget.getAttribute('id')){
      }{
      // Get the id from the clicked event element
      const eventid = e.currentTarget.getAttribute('id');
      //navigate to event detail
      window.location.href = "eventdetail?eventid=" + eventid 
      } 
     
    }
    
          // Function to fetch events
      const fetchEvents = async () => {
        try {
          // Make a GET request to the API endpoint that provides events
          // const response = await axios.get('/events'); // Replace with your actual API endpoint
          const response = await http.get('/events');
          // Check if the response data contains events
          if (response.data && Array.isArray(response.data)) {
            const events = response.data;
            setMyevents(events);
            // console.log(myevents)
          }
        } catch (error) {
          // Handle errors, e.g., display an error message or log the error
          console.error('Error fetching events:', error);
        }
      };

      const futureevents = () => {
        setMyfutureevents(true)
        setMypastevents(false)
        setallevents(false)
        const currentDate = new Date();
        const futureEvents = myevents.filter((event) => {
          const eventDate = new Date(event.startsAt);
          return eventDate > currentDate;
        });
        setMyevents(futureEvents);
      };
      
      const pastEvents = () => {
        setMyevents(0)
        setMyfutureevents(false)
        setMypastevents(true)
        setallevents(false)
        const currentDate = new Date();
        const pastEvents = myevents.filter((event) => {
          const eventDate = new Date(event.startsAt);
          return eventDate < currentDate;
        });
        console.log(pastEvents)

        setMyevents(pastEvents);
      };
      

      const allEvents = () => {
        setMyfutureevents(false)
        setMypastevents(false)
        setallevents(true)
        fetchEvents(); // Call the fetchEvents function to load all events
      };

      const handleSelectChange = (e) => {
        const selectedOption = e.target.value;
      
        if (selectedOption === "allevents") {
          setallevents(true);
          setMyfutureevents(false);
          setMypastevents(false);
          fetchEvents(); // Load all events
        } else if (selectedOption === "futureevents") {
          setallevents(false);
          setMyfutureevents(true);
          setMypastevents(false);
          futureevents(); // Call the function for future events
        } else if (selectedOption === "pastevents") {
          setallevents(false);
          setMyfutureevents(false);
          setMypastevents(true);
          pastEvents(); // Call the function for past events
        }
      };
      
      

      // Call the fetchEvents function when your component mounts
      useEffect(() => {
        fetchEvents();
      }, []);


return ( 
<div>

  <div className="app-page  flex-column flex-column-fluid">     
  <Header/>
    <br/>
        <div className="app-wrapper  flex-column flex-row-fluid " id="kt_app_wrapper">
          <div className="app-main flex-column flex-row-fluid " id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
              <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6">
                <div className="app-container  container-xxl d-flex flex-stack ">
                  <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                  <div className="link-container">
                      <a href="#"  onClick={allEvents} className={allevents? 'text-active': 'text-muted'}>ALL EVENTS</a>
                      <a href="#"  onClick={futureevents} className={myfutureevents? 'text-active': 'text-muted'}>FUTURE EVENTS</a>
                      <a href="#" onClick={pastEvents} className={mypastevents ? 'text-active': 'text-muted'}>PAST EVENTS</a>
                                         </div>
                  </div>

                  <div className='gap-2'>
                  <a href="#" onClick={() => setSelectedView('grid')} className={selectedView === 'grid' ? 'text-active': 'text-muted'}><i className="fa fa-th fa-4x list-icon" aria-hidden="true"></i></a>&nbsp;&nbsp;
                  <a href="#" onClick={() => setSelectedView('list')} className={selectedView === 'list' ?  'text-active': 'text-muted'}><i className="fa fa-th-list fa-4x list-icon" aria-hidden="true"></i></a>
                  </div>
                </div>
              </div>
              <div className="form-group mobileformgroup">
                <label htmlFor="showDropdown">SHOW :</label>
                <select id="showDropdown" onChange={handleSelectChange}>
                  <option value="allevents">ALL EVENTS</option>
                  <option value="futureevents">FUTURE EVENTS</option>
                  <option value="pastevents">PAST EVENTS</option>
                </select>
              </div>
              

              <br/>
              <div id="kt_app_content" className="app-content  flex-column-fluid ">
                <div id="kt_app_content_container" className="app-container  container-xxl ">
                 
                 
                  <div className={selectedView === 'grid' ? 'grid-view' : 'hidden'}>
                  <div className="row g-5 g-xl-12 mb-5 mb-xl-12"> 
                  {Array.isArray(myevents) && myevents.length > 0 ? (
                       myevents.map((event, index) => (
                        <div className="col-xl-4">
                          <div className="card card-flush overflow-hidden h-lg-100" >
                            <div className="card-header pt-5">
                              <p className="text-muted">{formatApiDate(event.createdAt)}</p>           
                            </div>
                            <div className="card-body">
                              <a href={`#`} onClick={handleEventDetail} id={event.id}><h3>{event.title}</h3></a>
                              <p className="text-muted">{event.owner.firstName} {event.owner.lastName}</p>
                              <br />
                              <p className="text-muted">{event.description}</p>
                            </div>
                            <div className="card-footer">
                              <div className="row">
                                <div className="col-lg-6 text-muted">
                                  <i className="fa fa-user" /> {event.attendees.length} of {event.capacity}
                                </div>
                                <div className="col-lg-6 text-muted">
                                  <button className="btn btn-secondary btn-sm card-button">EDIT</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="centered-container">
                      <img src="assets/media/loader.png" alt="Loading" />
                    </div>
                    )}
                  </div>
                  </div>
                  <div className={selectedView === 'list' ? 'list-view' : 'hidden'}>
                  {Array.isArray(myevents) && myevents.length > 0 ? (
                       myevents.map((event, index) => (
                  <div className="card" >
                    <div className='row p-8'>
                      <div className='col-lg-2'><h5 onClick={handleEventDetail} id={event.id}>{event.title}</h5></div>
                      <div className='col-lg-3'>{event.description}</div>
                      <div className='col-lg-1 text-muted'>{event.owner.firstName} {event.owner.lastName}</div>
                      <div className='col-lg-2 text-muted'>{formatApiDate(event.createdAt)}</div>
                      <div className='col-lg-2 text-muted'>{event.attendees.length} of {event.capacity}</div>
                      <div  className='col-lg-2 text-muted'>
                        <button  className='btn btn-sm btn-secondary'>Edit</button>
                      </div>
                    </div>
                    </div>
                    ))
                    ) : (
                    <div className="centered-container">
                      <img src="assets/media/loader.png" alt="Loading" />
                    </div>
                    )}
                  </div>
                  <br /> {/* Add a line break after each list item */}
                  </div> 
                </div>
              <Footer/>
            </div>
          </div>
        </div>
      </div>
      </div>
      );

  };

export default Dashboard;
