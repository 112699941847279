// hooks/http.js
import axios from 'axios';

// Retrieve the token from local storage
const token = localStorage.getItem('HutsyConnect_token');

// Create an Axios instance with default configuration
const http = axios.create({
  baseURL: 'https://test.apihutsy.com/api', // The API base URL
  // Add any other default configuration options here
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  },
});

export default http;
