// Header.js
import React from 'react';
import './styles/header.css';


function Eventheader() {
    const handleClose = () => {
        // Add logic to close the form or perform any other desired action
        console.log('Close icon clicked');
      };
  return (
    <header className="myheader">
      <div className="logo">
      <a href="/">
        <img src="assets/media/LogoE2.png" width={20} />
    </a>
      </div>
      <div className="icon">
      <a href='dashboard'>
      <div className="close-icon col-lg-6" onClick={handleClose}>
         &nbsp;Close
        </div>
    </a>
      </div>
    </header>
  );
}

export default Eventheader;



