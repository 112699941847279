// Header.js
import React from 'react';
import './styles/footer.css';


function Footer() {
  return (
    <div className="app-footer ">
    <div className="app-container container-xxl d-flex flex-column  flex-md-stack py-3">
        <div className="ml-auto"> 
          <a href='newevent'><img src='assets/media/btn_add.png'/></a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
