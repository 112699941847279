import React from 'react';
import './styles/signin.css';

  function Notfound(){
return ( 
<div>

    <div className="">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="sidelayout-wrap">
            <div>
              <img src="assets/media/LogoE.png" alt='layoutlogo' width={20} className="sidelayout-logo" />
            </div>
            <div >
              <h2 className="lowerText">
                GIVE YOURSELF <br />
                SOME CREDIT
              </h2>
            </div>
          </div>

          <div className="d-flex flex-lg-row-fluid w-lg-70 bgi-size-cover bgi-position-center signin-mobilepage">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
           
              <div className="p-10" style={{width: '500px'}}>
               
               <div>
                <span>
                <img style={{marginLeft: '-180px'}} alt='icon' src='assets/media/icon.png'/>
                {/* <p className='Notfoundheader' >404 Error - Page not Found</p> */}
                <div className='NotfoundText'>
                <p className="Notfoundheader">
                404 Error - Page not Found</p>
                <p className='NotfoundSubText'>Seems like Dark Vader just hit our website and dropped it. Hit the refresh button to take you back to the homepage</p>
                <a href='dashboard'  className='btn btn-primary homepageButton'>HOMEPAGE</a>
                </div>
              
                </span>
               </div>

               
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      );

  };

export default Notfound;
